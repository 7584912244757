/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Generated by karavan build tools - do NOT edit this file!
 */
import { v4 as uuidv4 } from 'uuid';
import { CamelElement } from "./IntegrationDefinition.js";
export class ProcessorDefinition extends CamelElement {
    stepName = 'processor';
    aggregate;
    bean;
    doCatch;
    choice;
    circuitBreaker;
    claimCheck;
    convertBodyTo;
    convertHeaderTo;
    convertVariableTo;
    delay;
    dynamicRouter;
    enrich;
    filter;
    doFinally;
    idempotentConsumer;
    kamelet;
    loadBalance;
    log;
    loop;
    marshal;
    multicast;
    onFallback;
    otherwise;
    pausable;
    pipeline;
    policy;
    pollEnrich;
    process;
    recipientList;
    removeHeader;
    removeHeaders;
    removeProperties;
    removeProperty;
    removeVariable;
    resequence;
    resumable;
    rollback;
    routingSlip;
    saga;
    sample;
    script;
    setBody;
    setExchangePattern;
    setHeader;
    setHeaders;
    setProperty;
    setVariable;
    sort;
    split;
    step;
    stop;
    threads;
    throttle;
    throwException;
    to;
    toD;
    transacted;
    transform;
    doTry;
    unmarshal;
    validate;
    when;
    whenSkipSendToEndpoint;
    wireTap;
    constructor(init) {
        super('ProcessorDefinition');
        Object.assign(this, init);
    }
}
export class BeansDeserializer extends CamelElement {
    constructor(init) {
        super('BeansDeserializer');
        Object.assign(this, init);
    }
}
export class ErrorHandlerBuilderDeserializer extends CamelElement {
    deadLetterChannel;
    defaultErrorHandler;
    jtaTransactionErrorHandler;
    noErrorHandler;
    refErrorHandler;
    springTransactionErrorHandler;
    constructor(init) {
        super('ErrorHandlerBuilderDeserializer');
        Object.assign(this, init);
    }
}
export class OutputAwareFromDefinition extends CamelElement {
    stepName = 'outputAwareFrom';
    description;
    id = 'outputAwareFrom-' + uuidv4().substring(0, 4);
    parameters = {};
    steps = [];
    uri = '';
    variableReceive;
    constructor(init) {
        super('OutputAwareFromDefinition');
        Object.assign(this, init);
    }
}
export class AggregateDefinition extends CamelElement {
    stepName = 'aggregate';
    id = 'aggregate-' + uuidv4().substring(0, 4);
    description;
    disabled;
    correlationExpression;
    completionPredicate;
    completionTimeoutExpression;
    completionSizeExpression;
    optimisticLockRetryPolicy;
    parallelProcessing;
    optimisticLocking;
    executorService;
    timeoutCheckerExecutorService;
    aggregateController;
    aggregationRepository;
    aggregationStrategy = '';
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    completionSize;
    completionInterval;
    completionTimeout;
    completionTimeoutCheckerInterval;
    completionFromBatchConsumer;
    completionOnNewCorrelationGroup;
    eagerCheckCompletion;
    ignoreInvalidCorrelationKeys;
    closeCorrelationKeyOnCompletion;
    discardOnCompletionTimeout;
    discardOnAggregationFailure;
    forceCompletionOnStop;
    completeAllOnStop;
    steps = [];
    constructor(init) {
        super('AggregateDefinition');
        Object.assign(this, init);
    }
}
export class BeanDefinition extends CamelElement {
    stepName = 'bean';
    id = 'bean-' + uuidv4().substring(0, 4);
    description;
    disabled;
    ref;
    method;
    beanType;
    scope;
    constructor(init) {
        super('BeanDefinition');
        Object.assign(this, init);
    }
}
export class CatchDefinition extends CamelElement {
    stepName = 'doCatch';
    id = 'doCatch-' + uuidv4().substring(0, 4);
    description;
    disabled;
    exception = [];
    onWhen;
    steps = [];
    constructor(init) {
        super('CatchDefinition');
        Object.assign(this, init);
    }
}
export class ChoiceDefinition extends CamelElement {
    stepName = 'choice';
    id = 'choice-' + uuidv4().substring(0, 4);
    description;
    disabled;
    when = [];
    otherwise;
    precondition;
    constructor(init) {
        super('ChoiceDefinition');
        Object.assign(this, init);
    }
}
export class CircuitBreakerDefinition extends CamelElement {
    stepName = 'circuitBreaker';
    id = 'circuitBreaker-' + uuidv4().substring(0, 4);
    description;
    disabled;
    configuration;
    resilience4jConfiguration;
    faultToleranceConfiguration;
    onFallback;
    steps = [];
    constructor(init) {
        super('CircuitBreakerDefinition');
        Object.assign(this, init);
    }
}
export class ClaimCheckDefinition extends CamelElement {
    stepName = 'claimCheck';
    id = 'claimCheck-' + uuidv4().substring(0, 4);
    description;
    disabled;
    operation;
    key;
    filter;
    aggregationStrategy;
    aggregationStrategyMethodName;
    constructor(init) {
        super('ClaimCheckDefinition');
        Object.assign(this, init);
    }
}
export class ContextScanDefinition extends CamelElement {
    stepName = 'contextScan';
    includeNonSingletons;
    excludes = [];
    includes = [];
    constructor(init) {
        super('ContextScanDefinition');
        Object.assign(this, init);
    }
}
export class ConvertBodyDefinition extends CamelElement {
    stepName = 'convertBodyTo';
    id = 'convertBodyTo-' + uuidv4().substring(0, 4);
    description;
    disabled;
    type = '';
    mandatory;
    charset;
    constructor(init) {
        super('ConvertBodyDefinition');
        Object.assign(this, init);
    }
}
export class ConvertHeaderDefinition extends CamelElement {
    stepName = 'convertHeaderTo';
    id = 'convertHeaderTo-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    type = '';
    toName;
    mandatory;
    charset;
    constructor(init) {
        super('ConvertHeaderDefinition');
        Object.assign(this, init);
    }
}
export class ConvertVariableDefinition extends CamelElement {
    stepName = 'convertVariableTo';
    id = 'convertVariableTo-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    type = '';
    toName;
    mandatory;
    charset;
    constructor(init) {
        super('ConvertVariableDefinition');
        Object.assign(this, init);
    }
}
export class DataFormatDefinition extends CamelElement {
    stepName = 'dataFormat';
    id = 'dataFormat-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('DataFormatDefinition');
        Object.assign(this, init);
    }
}
export class DelayDefinition extends CamelElement {
    stepName = 'delay';
    id = 'delay-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    asyncDelayed;
    callerRunsWhenRejected;
    executorService;
    constructor(init) {
        super('DelayDefinition');
        Object.assign(this, init);
    }
}
export class DynamicRouterDefinition extends CamelElement {
    stepName = 'dynamicRouter';
    id = 'dynamicRouter-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    uriDelimiter;
    ignoreInvalidEndpoints;
    cacheSize;
    constructor(init) {
        super('DynamicRouterDefinition');
        Object.assign(this, init);
    }
}
export class EnrichDefinition extends CamelElement {
    stepName = 'enrich';
    id = 'enrich-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    variableSend;
    variableReceive;
    aggregationStrategy;
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    aggregateOnException;
    shareUnitOfWork;
    cacheSize;
    ignoreInvalidEndpoint;
    allowOptimisedComponents;
    autoStartComponents;
    constructor(init) {
        super('EnrichDefinition');
        Object.assign(this, init);
    }
}
export class ErrorHandlerDefinition extends CamelElement {
    stepName = 'errorHandler';
    id = 'errorHandler-' + uuidv4().substring(0, 4);
    deadLetterChannel;
    defaultErrorHandler;
    jtaTransactionErrorHandler;
    noErrorHandler;
    springTransactionErrorHandler;
    constructor(init) {
        super('ErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class ExpressionSubElementDefinition extends CamelElement {
    stepName = 'expressionSubElement';
    constant;
    csimple;
    datasonnet;
    exchangeProperty;
    groovy;
    header;
    hl7terser;
    java;
    jq;
    js;
    jsonpath;
    language;
    method;
    mvel;
    ognl;
    python;
    ref;
    simple;
    spel;
    tokenize;
    variable;
    wasm;
    xpath;
    xquery;
    xtokenize;
    constructor(init) {
        super('ExpressionSubElementDefinition');
        Object.assign(this, init);
    }
}
export class FaultToleranceConfigurationDefinition extends CamelElement {
    stepName = 'faultToleranceConfiguration';
    id = 'faultToleranceConfiguration-' + uuidv4().substring(0, 4);
    circuitBreaker;
    delay;
    successThreshold;
    requestVolumeThreshold;
    failureRatio;
    timeoutEnabled;
    timeoutDuration;
    timeoutPoolSize;
    timeoutScheduledExecutorService;
    bulkheadEnabled;
    bulkheadMaxConcurrentCalls;
    bulkheadWaitingTaskQueue;
    bulkheadExecutorService;
    constructor(init) {
        super('FaultToleranceConfigurationDefinition');
        Object.assign(this, init);
    }
}
export class FilterDefinition extends CamelElement {
    stepName = 'filter';
    id = 'filter-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    statusPropertyName;
    steps = [];
    constructor(init) {
        super('FilterDefinition');
        Object.assign(this, init);
    }
}
export class FinallyDefinition extends CamelElement {
    stepName = 'doFinally';
    id = 'doFinally-' + uuidv4().substring(0, 4);
    description;
    disabled;
    steps = [];
    constructor(init) {
        super('FinallyDefinition');
        Object.assign(this, init);
    }
}
export class FromDefinition extends CamelElement {
    stepName = 'from';
    id = 'from-' + uuidv4().substring(0, 4);
    description;
    uri = '';
    variableReceive;
    parameters = {};
    steps = [];
    constructor(init) {
        super('FromDefinition');
        Object.assign(this, init);
    }
}
export class GlobalOptionDefinition extends CamelElement {
    stepName = 'globalOption';
    key = '';
    value = '';
    constructor(init) {
        super('GlobalOptionDefinition');
        Object.assign(this, init);
    }
}
export class GlobalOptionsDefinition extends CamelElement {
    stepName = 'globalOptions';
    globalOption = [];
    constructor(init) {
        super('GlobalOptionsDefinition');
        Object.assign(this, init);
    }
}
export class IdempotentConsumerDefinition extends CamelElement {
    stepName = 'idempotentConsumer';
    id = 'idempotentConsumer-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    idempotentRepository = '';
    eager;
    completionEager;
    skipDuplicate;
    removeOnFailure;
    steps = [];
    constructor(init) {
        super('IdempotentConsumerDefinition');
        Object.assign(this, init);
    }
}
export class InputTypeDefinition extends CamelElement {
    stepName = 'inputType';
    id = 'inputType-' + uuidv4().substring(0, 4);
    description;
    urn = '';
    validate;
    constructor(init) {
        super('InputTypeDefinition');
        Object.assign(this, init);
    }
}
export class InterceptDefinition extends CamelElement {
    stepName = 'intercept';
    id = 'intercept-' + uuidv4().substring(0, 4);
    description;
    disabled;
    steps = [];
    constructor(init) {
        super('InterceptDefinition');
        Object.assign(this, init);
    }
}
export class InterceptFromDefinition extends CamelElement {
    stepName = 'interceptFrom';
    id = 'interceptFrom-' + uuidv4().substring(0, 4);
    description;
    disabled;
    uri;
    steps = [];
    constructor(init) {
        super('InterceptFromDefinition');
        Object.assign(this, init);
    }
}
export class InterceptSendToEndpointDefinition extends CamelElement {
    stepName = 'interceptSendToEndpoint';
    id = 'interceptSendToEndpoint-' + uuidv4().substring(0, 4);
    description;
    disabled;
    uri = '';
    skipSendToOriginalEndpoint;
    afterUri;
    steps = [];
    constructor(init) {
        super('InterceptSendToEndpointDefinition');
        Object.assign(this, init);
    }
}
export class KameletDefinition extends CamelElement {
    stepName = 'kamelet';
    name = '';
    parameters = {};
    constructor(init) {
        super('KameletDefinition');
        Object.assign(this, init);
    }
}
export class LoadBalanceDefinition extends CamelElement {
    stepName = 'loadBalance';
    id = 'loadBalance-' + uuidv4().substring(0, 4);
    description;
    disabled;
    inheritErrorHandler;
    customLoadBalancer;
    failover;
    random;
    roundRobin;
    sticky;
    topic;
    weighted;
    steps = [];
    constructor(init) {
        super('LoadBalanceDefinition');
        Object.assign(this, init);
    }
}
export class LogDefinition extends CamelElement {
    stepName = 'log';
    id = 'log-' + uuidv4().substring(0, 4);
    description;
    disabled;
    message = '';
    loggingLevel;
    logName;
    marker;
    logger;
    constructor(init) {
        super('LogDefinition');
        Object.assign(this, init);
    }
}
export class LoopDefinition extends CamelElement {
    stepName = 'loop';
    id = 'loop-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    copy;
    doWhile;
    breakOnShutdown;
    steps = [];
    constructor(init) {
        super('LoopDefinition');
        Object.assign(this, init);
    }
}
export class MarshalDefinition extends CamelElement {
    stepName = 'marshal';
    id = 'marshal-' + uuidv4().substring(0, 4);
    description;
    disabled;
    variableSend;
    variableReceive;
    asn1;
    avro;
    barcode;
    base64;
    beanio;
    bindy;
    cbor;
    crypto;
    csv;
    custom;
    fhirJson;
    fhirXml;
    flatpack;
    grok;
    gzipDeflater;
    hl7;
    ical;
    jacksonXml;
    jaxb;
    json;
    jsonApi;
    lzf;
    mimeMultipart;
    parquetAvro;
    pgp;
    protobuf;
    rss;
    soap;
    swiftMt;
    swiftMx;
    syslog;
    tarFile;
    thrift;
    tidyMarkup;
    univocityCsv;
    univocityFixed;
    univocityTsv;
    xmlSecurity;
    yaml;
    zipDeflater;
    zipFile;
    constructor(init) {
        super('MarshalDefinition');
        Object.assign(this, init);
    }
}
export class MulticastDefinition extends CamelElement {
    stepName = 'multicast';
    id = 'multicast-' + uuidv4().substring(0, 4);
    description;
    disabled;
    aggregationStrategy;
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    parallelAggregate;
    parallelProcessing;
    synchronous;
    streaming;
    stopOnException;
    timeout;
    executorService;
    onPrepare;
    shareUnitOfWork;
    steps = [];
    constructor(init) {
        super('MulticastDefinition');
        Object.assign(this, init);
    }
}
export class OnCompletionDefinition extends CamelElement {
    stepName = 'onCompletion';
    id = 'onCompletion-' + uuidv4().substring(0, 4);
    description;
    disabled;
    mode;
    onCompleteOnly;
    onFailureOnly;
    parallelProcessing;
    executorService;
    useOriginalMessage;
    onWhen;
    steps = [];
    constructor(init) {
        super('OnCompletionDefinition');
        Object.assign(this, init);
    }
}
export class OnExceptionDefinition extends CamelElement {
    stepName = 'onException';
    id = 'onException-' + uuidv4().substring(0, 4);
    description;
    disabled;
    exception = [];
    onWhen;
    retryWhile;
    redeliveryPolicy;
    redeliveryPolicyRef;
    handled;
    continued;
    onRedeliveryRef;
    onExceptionOccurredRef;
    useOriginalMessage;
    useOriginalBody;
    steps = [];
    constructor(init) {
        super('OnExceptionDefinition');
        Object.assign(this, init);
    }
}
export class OnFallbackDefinition extends CamelElement {
    stepName = 'onFallback';
    id = 'onFallback-' + uuidv4().substring(0, 4);
    description;
    disabled;
    fallbackViaNetwork;
    steps = [];
    constructor(init) {
        super('OnFallbackDefinition');
        Object.assign(this, init);
    }
}
export class OptimisticLockRetryPolicyDefinition extends CamelElement {
    stepName = 'optimisticLockRetryPolicy';
    maximumRetries;
    retryDelay;
    maximumRetryDelay;
    exponentialBackOff;
    randomBackOff;
    constructor(init) {
        super('OptimisticLockRetryPolicyDefinition');
        Object.assign(this, init);
    }
}
export class OtherwiseDefinition extends CamelElement {
    stepName = 'otherwise';
    id = 'otherwise-' + uuidv4().substring(0, 4);
    description;
    disabled;
    steps = [];
    constructor(init) {
        super('OtherwiseDefinition');
        Object.assign(this, init);
    }
}
export class OutputDefinition extends CamelElement {
    stepName = 'output';
    description;
    disabled;
    id = 'output-' + uuidv4().substring(0, 4);
    steps = [];
    constructor(init) {
        super('OutputDefinition');
        Object.assign(this, init);
    }
}
export class OutputTypeDefinition extends CamelElement {
    stepName = 'outputType';
    id = 'outputType-' + uuidv4().substring(0, 4);
    description;
    urn = '';
    validate;
    constructor(init) {
        super('OutputTypeDefinition');
        Object.assign(this, init);
    }
}
export class PackageScanDefinition extends CamelElement {
    stepName = 'packageScan';
    package = [];
    excludes = [];
    includes = [];
    constructor(init) {
        super('PackageScanDefinition');
        Object.assign(this, init);
    }
}
export class PausableDefinition extends CamelElement {
    stepName = 'pausable';
    id = 'pausable-' + uuidv4().substring(0, 4);
    description;
    disabled;
    consumerListener = '';
    untilCheck = '';
    constructor(init) {
        super('PausableDefinition');
        Object.assign(this, init);
    }
}
export class PipelineDefinition extends CamelElement {
    stepName = 'pipeline';
    id = 'pipeline-' + uuidv4().substring(0, 4);
    description;
    disabled;
    steps = [];
    constructor(init) {
        super('PipelineDefinition');
        Object.assign(this, init);
    }
}
export class PolicyDefinition extends CamelElement {
    stepName = 'policy';
    id = 'policy-' + uuidv4().substring(0, 4);
    description;
    disabled;
    ref = '';
    steps = [];
    constructor(init) {
        super('PolicyDefinition');
        Object.assign(this, init);
    }
}
export class PollEnrichDefinition extends CamelElement {
    stepName = 'pollEnrich';
    id = 'pollEnrich-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    variableReceive;
    aggregationStrategy;
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    aggregateOnException;
    timeout;
    cacheSize;
    ignoreInvalidEndpoint;
    autoStartComponents;
    constructor(init) {
        super('PollEnrichDefinition');
        Object.assign(this, init);
    }
}
export class ProcessDefinition extends CamelElement {
    stepName = 'process';
    id = 'process-' + uuidv4().substring(0, 4);
    description;
    disabled;
    ref = '';
    constructor(init) {
        super('ProcessDefinition');
        Object.assign(this, init);
    }
}
export class PropertyDefinition extends CamelElement {
    stepName = 'property';
    key = '';
    value = '';
    constructor(init) {
        super('PropertyDefinition');
        Object.assign(this, init);
    }
}
export class PropertyExpressionDefinition extends CamelElement {
    stepName = 'propertyExpression';
    key = '';
    expression;
    constructor(init) {
        super('PropertyExpressionDefinition');
        Object.assign(this, init);
    }
}
export class RecipientListDefinition extends CamelElement {
    stepName = 'recipientList';
    id = 'recipientList-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    delimiter;
    aggregationStrategy;
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    parallelAggregate;
    parallelProcessing;
    synchronous;
    timeout;
    executorService;
    stopOnException;
    ignoreInvalidEndpoints;
    streaming;
    onPrepare;
    cacheSize;
    shareUnitOfWork;
    constructor(init) {
        super('RecipientListDefinition');
        Object.assign(this, init);
    }
}
export class RedeliveryPolicyDefinition extends CamelElement {
    stepName = 'redeliveryPolicy';
    id = 'redeliveryPolicy-' + uuidv4().substring(0, 4);
    maximumRedeliveries;
    redeliveryDelay;
    asyncDelayedRedelivery;
    backOffMultiplier;
    useExponentialBackOff;
    collisionAvoidanceFactor;
    useCollisionAvoidance;
    maximumRedeliveryDelay;
    retriesExhaustedLogLevel;
    retryAttemptedLogLevel;
    retryAttemptedLogInterval;
    logRetryAttempted;
    logStackTrace;
    logRetryStackTrace;
    logHandled;
    logNewException;
    logContinued;
    logExhausted;
    logExhaustedMessageHistory;
    logExhaustedMessageBody;
    disableRedelivery;
    delayPattern;
    allowRedeliveryWhileStopping;
    exchangeFormatterRef;
    constructor(init) {
        super('RedeliveryPolicyDefinition');
        Object.assign(this, init);
    }
}
export class RemoveHeaderDefinition extends CamelElement {
    stepName = 'removeHeader';
    id = 'removeHeader-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    constructor(init) {
        super('RemoveHeaderDefinition');
        Object.assign(this, init);
    }
}
export class RemoveHeadersDefinition extends CamelElement {
    stepName = 'removeHeaders';
    id = 'removeHeaders-' + uuidv4().substring(0, 4);
    description;
    disabled;
    pattern = '';
    excludePattern;
    constructor(init) {
        super('RemoveHeadersDefinition');
        Object.assign(this, init);
    }
}
export class RemovePropertiesDefinition extends CamelElement {
    stepName = 'removeProperties';
    id = 'removeProperties-' + uuidv4().substring(0, 4);
    description;
    disabled;
    pattern = '';
    excludePattern;
    constructor(init) {
        super('RemovePropertiesDefinition');
        Object.assign(this, init);
    }
}
export class RemovePropertyDefinition extends CamelElement {
    stepName = 'removeProperty';
    id = 'removeProperty-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    constructor(init) {
        super('RemovePropertyDefinition');
        Object.assign(this, init);
    }
}
export class RemoveVariableDefinition extends CamelElement {
    stepName = 'removeVariable';
    id = 'removeVariable-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    constructor(init) {
        super('RemoveVariableDefinition');
        Object.assign(this, init);
    }
}
export class ResequenceDefinition extends CamelElement {
    stepName = 'resequence';
    id = 'resequence-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    batchConfig;
    streamConfig;
    steps = [];
    constructor(init) {
        super('ResequenceDefinition');
        Object.assign(this, init);
    }
}
export class Resilience4jConfigurationDefinition extends CamelElement {
    stepName = 'resilience4jConfiguration';
    id = 'resilience4jConfiguration-' + uuidv4().substring(0, 4);
    circuitBreaker;
    config;
    failureRateThreshold;
    permittedNumberOfCallsInHalfOpenState;
    throwExceptionWhenHalfOpenOrOpenState;
    slidingWindowSize;
    slidingWindowType;
    minimumNumberOfCalls;
    writableStackTraceEnabled;
    waitDurationInOpenState;
    automaticTransitionFromOpenToHalfOpenEnabled;
    slowCallRateThreshold;
    slowCallDurationThreshold;
    bulkheadEnabled;
    bulkheadMaxConcurrentCalls;
    bulkheadMaxWaitDuration;
    timeoutEnabled;
    timeoutExecutorService;
    timeoutDuration;
    timeoutCancelRunningFuture;
    constructor(init) {
        super('Resilience4jConfigurationDefinition');
        Object.assign(this, init);
    }
}
export class RestContextRefDefinition extends CamelElement {
    stepName = 'restContextRef';
    ref = '';
    constructor(init) {
        super('RestContextRefDefinition');
        Object.assign(this, init);
    }
}
export class ResumableDefinition extends CamelElement {
    stepName = 'resumable';
    id = 'resumable-' + uuidv4().substring(0, 4);
    description;
    disabled;
    resumeStrategy = '';
    loggingLevel;
    intermittent;
    constructor(init) {
        super('ResumableDefinition');
        Object.assign(this, init);
    }
}
export class RollbackDefinition extends CamelElement {
    stepName = 'rollback';
    id = 'rollback-' + uuidv4().substring(0, 4);
    description;
    disabled;
    message;
    markRollbackOnly;
    markRollbackOnlyLast;
    constructor(init) {
        super('RollbackDefinition');
        Object.assign(this, init);
    }
}
export class RouteBuilderDefinition extends CamelElement {
    stepName = 'routeBuilder';
    id = 'routeBuilder-' + uuidv4().substring(0, 4);
    ref = '';
    constructor(init) {
        super('RouteBuilderDefinition');
        Object.assign(this, init);
    }
}
export class RouteConfigurationContextRefDefinition extends CamelElement {
    stepName = 'routeConfigurationContextRef';
    ref = '';
    constructor(init) {
        super('RouteConfigurationContextRefDefinition');
        Object.assign(this, init);
    }
}
export class RouteConfigurationDefinition extends CamelElement {
    stepName = 'routeConfiguration';
    id;
    description;
    errorHandler;
    intercept = [];
    interceptFrom = [];
    interceptSendToEndpoint = [];
    onException = [];
    onCompletion = [];
    precondition;
    constructor(init) {
        super('RouteConfigurationDefinition');
        Object.assign(this, init);
    }
}
export class RouteContextRefDefinition extends CamelElement {
    stepName = 'routeContextRef';
    ref = '';
    constructor(init) {
        super('RouteContextRefDefinition');
        Object.assign(this, init);
    }
}
export class RouteDefinition extends CamelElement {
    stepName = 'route';
    id = 'route-' + uuidv4().substring(0, 4);
    description;
    group;
    nodePrefixId;
    routeConfigurationId;
    autoStartup;
    startupOrder;
    trace;
    messageHistory;
    logMask;
    shutdownRoute;
    shutdownRunningTask;
    precondition;
    inputType;
    outputType;
    from = new FromDefinition();
    constructor(init) {
        super('RouteDefinition');
        Object.assign(this, init);
    }
}
export class RouteTemplateBeanDefinition extends CamelElement {
    stepName = 'routeTemplateBean';
    name = '';
    properties = {};
    property = [];
    script;
    scriptLanguage;
    type = '';
    constructor(init) {
        super('RouteTemplateBeanDefinition');
        Object.assign(this, init);
    }
}
export class RouteTemplateDefinition extends CamelElement {
    stepName = 'routeTemplate';
    id = 'routeTemplate-' + uuidv4().substring(0, 4);
    description;
    route;
    beans = [];
    from;
    parameters = [];
    constructor(init) {
        super('RouteTemplateDefinition');
        Object.assign(this, init);
    }
}
export class RouteTemplateParameterDefinition extends CamelElement {
    stepName = 'routeTemplateParameter';
    defaultValue;
    description;
    name = '';
    required;
    constructor(init) {
        super('RouteTemplateParameterDefinition');
        Object.assign(this, init);
    }
}
export class RoutingSlipDefinition extends CamelElement {
    stepName = 'routingSlip';
    id = 'routingSlip-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    uriDelimiter;
    ignoreInvalidEndpoints;
    cacheSize;
    constructor(init) {
        super('RoutingSlipDefinition');
        Object.assign(this, init);
    }
}
export class SagaActionUriDefinition extends CamelElement {
    stepName = 'sagaActionUri';
    description;
    disabled;
    id = 'sagaActionUri-' + uuidv4().substring(0, 4);
    parameters = {};
    uri = '';
    constructor(init) {
        super('SagaActionUriDefinition');
        Object.assign(this, init);
    }
}
export class SagaDefinition extends CamelElement {
    stepName = 'saga';
    id = 'saga-' + uuidv4().substring(0, 4);
    description;
    disabled;
    sagaService;
    propagation;
    completionMode;
    timeout;
    compensation;
    completion;
    option = [];
    steps = [];
    constructor(init) {
        super('SagaDefinition');
        Object.assign(this, init);
    }
}
export class SamplingDefinition extends CamelElement {
    stepName = 'sample';
    id = 'sample-' + uuidv4().substring(0, 4);
    description;
    disabled;
    samplePeriod;
    messageFrequency;
    constructor(init) {
        super('SamplingDefinition');
        Object.assign(this, init);
    }
}
export class ScriptDefinition extends CamelElement {
    stepName = 'script';
    id = 'script-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    constructor(init) {
        super('ScriptDefinition');
        Object.assign(this, init);
    }
}
export class SetBodyDefinition extends CamelElement {
    stepName = 'setBody';
    id = 'setBody-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    constructor(init) {
        super('SetBodyDefinition');
        Object.assign(this, init);
    }
}
export class SetExchangePatternDefinition extends CamelElement {
    stepName = 'setExchangePattern';
    id = 'setExchangePattern-' + uuidv4().substring(0, 4);
    description;
    disabled;
    pattern;
    constructor(init) {
        super('SetExchangePatternDefinition');
        Object.assign(this, init);
    }
}
export class SetHeaderDefinition extends CamelElement {
    stepName = 'setHeader';
    id = 'setHeader-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    expression;
    constructor(init) {
        super('SetHeaderDefinition');
        Object.assign(this, init);
    }
}
export class SetHeadersDefinition extends CamelElement {
    stepName = 'setHeaders';
    id = 'setHeaders-' + uuidv4().substring(0, 4);
    description;
    disabled;
    headers = [];
    constructor(init) {
        super('SetHeadersDefinition');
        Object.assign(this, init);
    }
}
export class SetPropertyDefinition extends CamelElement {
    stepName = 'setProperty';
    id = 'setProperty-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    expression;
    constructor(init) {
        super('SetPropertyDefinition');
        Object.assign(this, init);
    }
}
export class SetVariableDefinition extends CamelElement {
    stepName = 'setVariable';
    id = 'setVariable-' + uuidv4().substring(0, 4);
    description;
    disabled;
    name = '';
    expression;
    constructor(init) {
        super('SetVariableDefinition');
        Object.assign(this, init);
    }
}
export class SortDefinition extends CamelElement {
    stepName = 'sort';
    id = 'sort-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    comparator;
    constructor(init) {
        super('SortDefinition');
        Object.assign(this, init);
    }
}
export class SplitDefinition extends CamelElement {
    stepName = 'split';
    id = 'split-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    delimiter;
    aggregationStrategy;
    aggregationStrategyMethodName;
    aggregationStrategyMethodAllowNull;
    parallelAggregate;
    parallelProcessing;
    synchronous;
    streaming;
    stopOnException;
    timeout;
    executorService;
    onPrepare;
    shareUnitOfWork;
    steps = [];
    constructor(init) {
        super('SplitDefinition');
        Object.assign(this, init);
    }
}
export class StepDefinition extends CamelElement {
    stepName = 'step';
    id = 'step-' + uuidv4().substring(0, 4);
    description;
    disabled;
    steps = [];
    constructor(init) {
        super('StepDefinition');
        Object.assign(this, init);
    }
}
export class StopDefinition extends CamelElement {
    stepName = 'stop';
    id = 'stop-' + uuidv4().substring(0, 4);
    description;
    disabled;
    constructor(init) {
        super('StopDefinition');
        Object.assign(this, init);
    }
}
export class TemplatedRouteBeanDefinition extends CamelElement {
    stepName = 'templatedRouteBean';
    name = '';
    type = '';
    scriptLanguage;
    property = [];
    properties = {};
    script;
    constructor(init) {
        super('TemplatedRouteBeanDefinition');
        Object.assign(this, init);
    }
}
export class TemplatedRouteDefinition extends CamelElement {
    stepName = 'templatedRoute';
    routeTemplateRef = '';
    routeId;
    prefixId;
    beans = [];
    parameters = [];
    constructor(init) {
        super('TemplatedRouteDefinition');
        Object.assign(this, init);
    }
}
export class TemplatedRouteParameterDefinition extends CamelElement {
    stepName = 'templatedRouteParameter';
    name = '';
    value = '';
    constructor(init) {
        super('TemplatedRouteParameterDefinition');
        Object.assign(this, init);
    }
}
export class ThreadPoolProfileDefinition extends CamelElement {
    stepName = 'threadPoolProfile';
    id = 'threadPoolProfile-' + uuidv4().substring(0, 4);
    description;
    defaultProfile;
    poolSize;
    maxPoolSize;
    keepAliveTime;
    timeUnit;
    maxQueueSize;
    allowCoreThreadTimeOut;
    rejectedPolicy;
    constructor(init) {
        super('ThreadPoolProfileDefinition');
        Object.assign(this, init);
    }
}
export class ThreadsDefinition extends CamelElement {
    stepName = 'threads';
    id = 'threads-' + uuidv4().substring(0, 4);
    description;
    disabled;
    executorService;
    poolSize;
    maxPoolSize;
    keepAliveTime;
    timeUnit;
    maxQueueSize;
    allowCoreThreadTimeOut;
    threadName;
    rejectedPolicy;
    callerRunsWhenRejected;
    constructor(init) {
        super('ThreadsDefinition');
        Object.assign(this, init);
    }
}
export class ThrottleDefinition extends CamelElement {
    stepName = 'throttle';
    id = 'throttle-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    mode;
    correlationExpression;
    executorService;
    asyncDelayed;
    callerRunsWhenRejected;
    rejectExecution;
    timePeriodMillis;
    constructor(init) {
        super('ThrottleDefinition');
        Object.assign(this, init);
    }
}
export class ThrowExceptionDefinition extends CamelElement {
    stepName = 'throwException';
    id = 'throwException-' + uuidv4().substring(0, 4);
    description;
    disabled;
    message;
    exceptionType;
    ref;
    constructor(init) {
        super('ThrowExceptionDefinition');
        Object.assign(this, init);
    }
}
export class ToDefinition extends CamelElement {
    stepName = 'to';
    id = 'to-' + uuidv4().substring(0, 4);
    description;
    disabled;
    variableSend;
    variableReceive;
    uri = '';
    pattern;
    parameters = {};
    constructor(init) {
        super('ToDefinition');
        Object.assign(this, init);
    }
}
export class ToDynamicDefinition extends CamelElement {
    stepName = 'toD';
    id = 'toD-' + uuidv4().substring(0, 4);
    description;
    disabled;
    uri = '';
    variableSend;
    variableReceive;
    pattern;
    cacheSize;
    ignoreInvalidEndpoint;
    allowOptimisedComponents;
    autoStartComponents;
    parameters = {};
    constructor(init) {
        super('ToDynamicDefinition');
        Object.assign(this, init);
    }
}
export class TransactedDefinition extends CamelElement {
    stepName = 'transacted';
    id = 'transacted-' + uuidv4().substring(0, 4);
    description;
    disabled;
    ref;
    steps = [];
    constructor(init) {
        super('TransactedDefinition');
        Object.assign(this, init);
    }
}
export class TransformDefinition extends CamelElement {
    stepName = 'transform';
    id = 'transform-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    fromType;
    toType;
    constructor(init) {
        super('TransformDefinition');
        Object.assign(this, init);
    }
}
export class TryDefinition extends CamelElement {
    stepName = 'doTry';
    id = 'doTry-' + uuidv4().substring(0, 4);
    description;
    disabled;
    doCatch = [];
    doFinally;
    steps = [];
    constructor(init) {
        super('TryDefinition');
        Object.assign(this, init);
    }
}
export class UnmarshalDefinition extends CamelElement {
    stepName = 'unmarshal';
    id = 'unmarshal-' + uuidv4().substring(0, 4);
    description;
    disabled;
    variableSend;
    variableReceive;
    allowNullBody;
    asn1;
    avro;
    barcode;
    base64;
    beanio;
    bindy;
    cbor;
    crypto;
    csv;
    custom;
    fhirJson;
    fhirXml;
    flatpack;
    grok;
    gzipDeflater;
    hl7;
    ical;
    jacksonXml;
    jaxb;
    json;
    jsonApi;
    lzf;
    mimeMultipart;
    parquetAvro;
    pgp;
    protobuf;
    rss;
    soap;
    swiftMt;
    swiftMx;
    syslog;
    tarFile;
    thrift;
    tidyMarkup;
    univocityCsv;
    univocityFixed;
    univocityTsv;
    xmlSecurity;
    yaml;
    zipDeflater;
    zipFile;
    constructor(init) {
        super('UnmarshalDefinition');
        Object.assign(this, init);
    }
}
export class ValidateDefinition extends CamelElement {
    stepName = 'validate';
    id = 'validate-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    predicateExceptionFactory;
    constructor(init) {
        super('ValidateDefinition');
        Object.assign(this, init);
    }
}
export class ValueDefinition extends CamelElement {
    stepName = 'value';
    value;
    constructor(init) {
        super('ValueDefinition');
        Object.assign(this, init);
    }
}
export class WhenDefinition extends CamelElement {
    stepName = 'when';
    id = 'when-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    steps = [];
    constructor(init) {
        super('WhenDefinition');
        Object.assign(this, init);
    }
}
export class WhenSkipSendToEndpointDefinition extends CamelElement {
    stepName = 'whenSkipSendToEndpoint';
    id = 'whenSkipSendToEndpoint-' + uuidv4().substring(0, 4);
    description;
    disabled;
    expression;
    steps = [];
    constructor(init) {
        super('WhenSkipSendToEndpointDefinition');
        Object.assign(this, init);
    }
}
export class WireTapDefinition extends CamelElement {
    stepName = 'wireTap';
    id = 'wireTap-' + uuidv4().substring(0, 4);
    description;
    disabled;
    copy;
    dynamicUri;
    onPrepare;
    executorService;
    uri = '';
    variableSend;
    variableReceive;
    pattern;
    cacheSize;
    ignoreInvalidEndpoint;
    allowOptimisedComponents;
    autoStartComponents;
    parameters = {};
    constructor(init) {
        super('WireTapDefinition');
        Object.assign(this, init);
    }
}
export class BeanConstructorDefinition extends CamelElement {
    stepName = 'beanConstructor';
    index;
    value = '';
    constructor(init) {
        super('BeanConstructorDefinition');
        Object.assign(this, init);
    }
}
export class BeanConstructorsDefinition extends CamelElement {
    stepName = 'beanConstructors';
    _constructor = [];
    constructor(init) {
        super('BeanConstructorsDefinition');
        Object.assign(this, init);
    }
}
export class BeanPropertiesDefinition extends CamelElement {
    stepName = 'properties';
    property = [];
    constructor(init) {
        super('BeanPropertiesDefinition');
        Object.assign(this, init);
    }
}
export class BeanPropertyDefinition extends CamelElement {
    stepName = 'beanProperty';
    key;
    properties;
    value;
    constructor(init) {
        super('BeanPropertyDefinition');
        Object.assign(this, init);
    }
}
export class ComponentScanDefinition extends CamelElement {
    stepName = 'componentScan';
    basePackage;
    constructor(init) {
        super('ComponentScanDefinition');
        Object.assign(this, init);
    }
}
export class RegistryBeanDefinition extends CamelElement {
    stepName = 'registryBean';
    builderClass;
    builderMethod;
    constructors = {};
    destroyMethod;
    factoryBean;
    factoryMethod;
    initMethod;
    name = '';
    properties = {};
    script;
    scriptLanguage;
    type = '';
    constructor(init) {
        super('RegistryBeanDefinition');
        Object.assign(this, init);
    }
}
export class BatchResequencerConfig extends CamelElement {
    batchSize;
    batchTimeout;
    allowDuplicates;
    reverse;
    ignoreInvalidExchanges;
    constructor(init) {
        super('BatchResequencerConfig');
        Object.assign(this, init);
    }
}
export class StreamResequencerConfig extends CamelElement {
    capacity;
    timeout;
    deliveryAttemptInterval;
    ignoreInvalidExchanges;
    rejectOld;
    comparator;
    constructor(init) {
        super('StreamResequencerConfig');
        Object.assign(this, init);
    }
}
export class ASN1DataFormat extends CamelElement {
    dataFormatName = 'asn1';
    id = 'asn1-' + uuidv4().substring(0, 4);
    unmarshalType;
    usingIterator;
    constructor(init) {
        super('ASN1DataFormat');
        Object.assign(this, init);
    }
}
export class AvroDataFormat extends CamelElement {
    dataFormatName = 'avro';
    id = 'avro-' + uuidv4().substring(0, 4);
    instanceClassName;
    library;
    objectMapper;
    useDefaultObjectMapper;
    unmarshalType;
    jsonView;
    include;
    allowJmsType;
    collectionType;
    useList;
    moduleClassNames;
    moduleRefs;
    enableFeatures;
    disableFeatures;
    allowUnmarshallType;
    timezone;
    autoDiscoverObjectMapper;
    contentTypeHeader;
    schemaResolver;
    autoDiscoverSchemaResolver;
    constructor(init) {
        super('AvroDataFormat');
        Object.assign(this, init);
    }
}
export class BarcodeDataFormat extends CamelElement {
    dataFormatName = 'barcode';
    id = 'barcode-' + uuidv4().substring(0, 4);
    barcodeFormat;
    imageType;
    width;
    height;
    constructor(init) {
        super('BarcodeDataFormat');
        Object.assign(this, init);
    }
}
export class Base64DataFormat extends CamelElement {
    dataFormatName = 'base64';
    id = 'base64-' + uuidv4().substring(0, 4);
    lineLength;
    lineSeparator;
    urlSafe;
    constructor(init) {
        super('Base64DataFormat');
        Object.assign(this, init);
    }
}
export class BeanioDataFormat extends CamelElement {
    dataFormatName = 'beanio';
    id = 'beanio-' + uuidv4().substring(0, 4);
    mapping = '';
    streamName = '';
    ignoreUnidentifiedRecords;
    ignoreUnexpectedRecords;
    ignoreInvalidRecords;
    encoding;
    beanReaderErrorHandlerType;
    unmarshalSingleObject;
    constructor(init) {
        super('BeanioDataFormat');
        Object.assign(this, init);
    }
}
export class BindyDataFormat extends CamelElement {
    dataFormatName = 'bindy';
    id = 'bindy-' + uuidv4().substring(0, 4);
    type;
    classType;
    allowEmptyStream;
    unwrapSingleInstance;
    locale;
    constructor(init) {
        super('BindyDataFormat');
        Object.assign(this, init);
    }
}
export class CBORDataFormat extends CamelElement {
    dataFormatName = 'cbor';
    id = 'cbor-' + uuidv4().substring(0, 4);
    objectMapper;
    useDefaultObjectMapper;
    unmarshalType;
    collectionType;
    useList;
    allowUnmarshallType;
    prettyPrint;
    allowJmsType;
    enableFeatures;
    disableFeatures;
    constructor(init) {
        super('CBORDataFormat');
        Object.assign(this, init);
    }
}
export class CryptoDataFormat extends CamelElement {
    dataFormatName = 'crypto';
    id = 'crypto-' + uuidv4().substring(0, 4);
    algorithm;
    keyRef;
    cryptoProvider;
    initVectorRef;
    algorithmParameterRef;
    bufferSize;
    macAlgorithm;
    shouldAppendHMAC;
    inline;
    constructor(init) {
        super('CryptoDataFormat');
        Object.assign(this, init);
    }
}
export class CsvDataFormat extends CamelElement {
    dataFormatName = 'csv';
    id = 'csv-' + uuidv4().substring(0, 4);
    formatRef;
    formatName;
    commentMarkerDisabled;
    commentMarker;
    delimiter;
    escapeDisabled;
    escape;
    headerDisabled;
    header = [];
    allowMissingColumnNames;
    ignoreEmptyLines;
    ignoreSurroundingSpaces;
    nullStringDisabled;
    nullString;
    quoteDisabled;
    quote;
    recordSeparatorDisabled;
    recordSeparator;
    skipHeaderRecord;
    quoteMode;
    ignoreHeaderCase;
    trim;
    trailingDelimiter;
    marshallerFactoryRef;
    lazyLoad;
    useMaps;
    useOrderedMaps;
    recordConverterRef;
    captureHeaderRecord;
    constructor(init) {
        super('CsvDataFormat');
        Object.assign(this, init);
    }
}
export class CustomDataFormat extends CamelElement {
    dataFormatName = 'custom';
    id = 'custom-' + uuidv4().substring(0, 4);
    ref = '';
    constructor(init) {
        super('CustomDataFormat');
        Object.assign(this, init);
    }
}
export class DataFormatsDefinition extends CamelElement {
    stepName = 'dataFormats';
    asn1;
    avro;
    barcode;
    base64;
    beanio;
    bindy;
    cbor;
    crypto;
    csv;
    custom;
    fhirJson;
    fhirXml;
    flatpack;
    grok;
    gzipDeflater;
    hl7;
    ical;
    jacksonXml;
    jaxb;
    json;
    jsonApi;
    lzf;
    mimeMultipart;
    parquetAvro;
    pgp;
    protobuf;
    rss;
    soap;
    swiftMt;
    swiftMx;
    syslog;
    tarFile;
    thrift;
    tidyMarkup;
    univocityCsv;
    univocityFixed;
    univocityTsv;
    xmlSecurity;
    yaml;
    zipDeflater;
    zipFile;
    constructor(init) {
        super('DataFormatsDefinition');
        Object.assign(this, init);
    }
}
export class FhirJsonDataFormat extends CamelElement {
    dataFormatName = 'fhirJson';
    id = 'fhirJson-' + uuidv4().substring(0, 4);
    fhirVersion;
    fhirContext;
    prettyPrint;
    parserErrorHandler;
    parserOptions;
    preferTypes;
    forceResourceId;
    serverBaseUrl;
    omitResourceId;
    encodeElementsAppliesToChildResourcesOnly;
    encodeElements;
    dontEncodeElements;
    stripVersionsFromReferences;
    overrideResourceIdWithBundleEntryFullUrl;
    summaryMode;
    suppressNarratives;
    dontStripVersionsFromReferencesAtPaths;
    contentTypeHeader;
    constructor(init) {
        super('FhirJsonDataFormat');
        Object.assign(this, init);
    }
}
export class FhirXmlDataFormat extends CamelElement {
    dataFormatName = 'fhirXml';
    id = 'fhirXml-' + uuidv4().substring(0, 4);
    fhirVersion;
    fhirContext;
    prettyPrint;
    parserErrorHandler;
    parserOptions;
    preferTypes;
    forceResourceId;
    serverBaseUrl;
    omitResourceId;
    encodeElementsAppliesToChildResourcesOnly;
    encodeElements;
    dontEncodeElements;
    stripVersionsFromReferences;
    overrideResourceIdWithBundleEntryFullUrl;
    summaryMode;
    suppressNarratives;
    dontStripVersionsFromReferencesAtPaths;
    contentTypeHeader;
    constructor(init) {
        super('FhirXmlDataFormat');
        Object.assign(this, init);
    }
}
export class FlatpackDataFormat extends CamelElement {
    dataFormatName = 'flatpack';
    id = 'flatpack-' + uuidv4().substring(0, 4);
    definition;
    fixed;
    delimiter;
    ignoreFirstRecord;
    allowShortLines;
    ignoreExtraColumns;
    textQualifier;
    parserFactoryRef;
    constructor(init) {
        super('FlatpackDataFormat');
        Object.assign(this, init);
    }
}
export class GrokDataFormat extends CamelElement {
    dataFormatName = 'grok';
    id = 'grok-' + uuidv4().substring(0, 4);
    pattern = '';
    flattened;
    allowMultipleMatchesPerLine;
    namedOnly;
    constructor(init) {
        super('GrokDataFormat');
        Object.assign(this, init);
    }
}
export class GzipDeflaterDataFormat extends CamelElement {
    dataFormatName = 'gzipDeflater';
    id = 'gzipDeflater-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('GzipDeflaterDataFormat');
        Object.assign(this, init);
    }
}
export class HL7DataFormat extends CamelElement {
    dataFormatName = 'hl7';
    id = 'hl7-' + uuidv4().substring(0, 4);
    validate;
    constructor(init) {
        super('HL7DataFormat');
        Object.assign(this, init);
    }
}
export class IcalDataFormat extends CamelElement {
    dataFormatName = 'ical';
    id = 'ical-' + uuidv4().substring(0, 4);
    validating;
    constructor(init) {
        super('IcalDataFormat');
        Object.assign(this, init);
    }
}
export class JacksonXMLDataFormat extends CamelElement {
    dataFormatName = 'jacksonXml';
    id = 'jacksonXml-' + uuidv4().substring(0, 4);
    xmlMapper;
    prettyPrint;
    unmarshalType;
    allowUnmarshallType;
    jsonView;
    include;
    allowJmsType;
    collectionType;
    useList;
    timezone;
    enableJaxbAnnotationModule;
    moduleClassNames;
    moduleRefs;
    enableFeatures;
    disableFeatures;
    contentTypeHeader;
    constructor(init) {
        super('JacksonXMLDataFormat');
        Object.assign(this, init);
    }
}
export class JaxbDataFormat extends CamelElement {
    dataFormatName = 'jaxb';
    id = 'jaxb-' + uuidv4().substring(0, 4);
    contextPath = '';
    contextPathIsClassName;
    schema;
    schemaSeverityLevel;
    prettyPrint;
    objectFactory;
    ignoreJAXBElement;
    mustBeJAXBElement;
    filterNonXmlChars;
    encoding;
    fragment;
    partClass;
    partNamespace;
    namespacePrefixRef;
    xmlStreamWriterWrapper;
    schemaLocation;
    noNamespaceSchemaLocation;
    jaxbProviderProperties;
    contentTypeHeader;
    accessExternalSchemaProtocols;
    constructor(init) {
        super('JaxbDataFormat');
        Object.assign(this, init);
    }
}
export class JsonApiDataFormat extends CamelElement {
    dataFormatName = 'jsonApi';
    id = 'jsonApi-' + uuidv4().substring(0, 4);
    dataFormatTypes;
    mainFormatType;
    constructor(init) {
        super('JsonApiDataFormat');
        Object.assign(this, init);
    }
}
export class JsonDataFormat extends CamelElement {
    dataFormatName = 'json';
    id = 'json-' + uuidv4().substring(0, 4);
    objectMapper;
    useDefaultObjectMapper;
    autoDiscoverObjectMapper;
    prettyPrint;
    library;
    unmarshalType;
    jsonView;
    include;
    allowJmsType;
    collectionType;
    useList;
    moduleClassNames;
    moduleRefs;
    enableFeatures;
    disableFeatures;
    allowUnmarshallType;
    timezone;
    schemaResolver;
    autoDiscoverSchemaResolver;
    namingStrategy;
    contentTypeHeader;
    dateFormatPattern;
    constructor(init) {
        super('JsonDataFormat');
        Object.assign(this, init);
    }
}
export class LZFDataFormat extends CamelElement {
    dataFormatName = 'lzf';
    id = 'lzf-' + uuidv4().substring(0, 4);
    usingParallelCompression;
    constructor(init) {
        super('LZFDataFormat');
        Object.assign(this, init);
    }
}
export class MimeMultipartDataFormat extends CamelElement {
    dataFormatName = 'mimeMultipart';
    id = 'mimeMultipart-' + uuidv4().substring(0, 4);
    multipartSubType;
    multipartWithoutAttachment;
    headersInline;
    includeHeaders;
    binaryContent;
    constructor(init) {
        super('MimeMultipartDataFormat');
        Object.assign(this, init);
    }
}
export class PGPDataFormat extends CamelElement {
    dataFormatName = 'pgp';
    id = 'pgp-' + uuidv4().substring(0, 4);
    keyUserid;
    signatureKeyUserid;
    password;
    signaturePassword;
    keyFileName;
    signatureKeyFileName;
    signatureKeyRing;
    armored;
    integrity;
    provider;
    algorithm;
    compressionAlgorithm;
    hashAlgorithm;
    signatureVerificationOption;
    constructor(init) {
        super('PGPDataFormat');
        Object.assign(this, init);
    }
}
export class ParquetAvroDataFormat extends CamelElement {
    dataFormatName = 'parquetAvro';
    id = 'parquetAvro-' + uuidv4().substring(0, 4);
    compressionCodecName;
    unmarshalType;
    lazyLoad;
    constructor(init) {
        super('ParquetAvroDataFormat');
        Object.assign(this, init);
    }
}
export class ProtobufDataFormat extends CamelElement {
    dataFormatName = 'protobuf';
    id = 'protobuf-' + uuidv4().substring(0, 4);
    instanceClass;
    objectMapper;
    useDefaultObjectMapper;
    autoDiscoverObjectMapper;
    library;
    unmarshalType;
    jsonView;
    include;
    allowJmsType;
    collectionType;
    useList;
    moduleClassNames;
    moduleRefs;
    enableFeatures;
    disableFeatures;
    allowUnmarshallType;
    timezone;
    schemaResolver;
    autoDiscoverSchemaResolver;
    contentTypeFormat;
    contentTypeHeader;
    constructor(init) {
        super('ProtobufDataFormat');
        Object.assign(this, init);
    }
}
export class RssDataFormat extends CamelElement {
    dataFormatName = 'rss';
    id = 'rss-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('RssDataFormat');
        Object.assign(this, init);
    }
}
export class SoapDataFormat extends CamelElement {
    dataFormatName = 'soap';
    id = 'soap-' + uuidv4().substring(0, 4);
    contextPath = '';
    encoding;
    elementNameStrategyRef;
    version;
    namespacePrefixRef;
    schema;
    constructor(init) {
        super('SoapDataFormat');
        Object.assign(this, init);
    }
}
export class SwiftMtDataFormat extends CamelElement {
    dataFormatName = 'swiftMt';
    id = 'swiftMt-' + uuidv4().substring(0, 4);
    writeInJson;
    constructor(init) {
        super('SwiftMtDataFormat');
        Object.assign(this, init);
    }
}
export class SwiftMxDataFormat extends CamelElement {
    dataFormatName = 'swiftMx';
    id = 'swiftMx-' + uuidv4().substring(0, 4);
    writeConfigRef;
    writeInJson;
    readMessageId;
    readConfigRef;
    constructor(init) {
        super('SwiftMxDataFormat');
        Object.assign(this, init);
    }
}
export class SyslogDataFormat extends CamelElement {
    dataFormatName = 'syslog';
    id = 'syslog-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('SyslogDataFormat');
        Object.assign(this, init);
    }
}
export class TarFileDataFormat extends CamelElement {
    dataFormatName = 'tarFile';
    id = 'tarFile-' + uuidv4().substring(0, 4);
    usingIterator;
    allowEmptyDirectory;
    preservePathElements;
    maxDecompressedSize;
    constructor(init) {
        super('TarFileDataFormat');
        Object.assign(this, init);
    }
}
export class ThriftDataFormat extends CamelElement {
    dataFormatName = 'thrift';
    id = 'thrift-' + uuidv4().substring(0, 4);
    instanceClass;
    contentTypeFormat;
    contentTypeHeader;
    constructor(init) {
        super('ThriftDataFormat');
        Object.assign(this, init);
    }
}
export class TidyMarkupDataFormat extends CamelElement {
    dataFormatName = 'tidyMarkup';
    id = 'tidyMarkup-' + uuidv4().substring(0, 4);
    dataObjectType;
    omitXmlDeclaration;
    constructor(init) {
        super('TidyMarkupDataFormat');
        Object.assign(this, init);
    }
}
export class UniVocityCsvDataFormat extends CamelElement {
    dataFormatName = 'univocityCsv';
    id = 'univocityCsv-' + uuidv4().substring(0, 4);
    delimiter;
    quoteAllFields;
    quote;
    quoteEscape;
    nullValue;
    skipEmptyLines;
    ignoreTrailingWhitespaces;
    ignoreLeadingWhitespaces;
    headersDisabled;
    headerExtractionEnabled;
    numberOfRecordsToRead;
    emptyValue;
    lineSeparator;
    normalizedLineSeparator;
    comment;
    lazyLoad;
    asMap;
    univocityHeader = [];
    constructor(init) {
        super('UniVocityCsvDataFormat');
        Object.assign(this, init);
    }
}
export class UniVocityFixedDataFormat extends CamelElement {
    dataFormatName = 'univocityFixed';
    id = 'univocityFixed-' + uuidv4().substring(0, 4);
    padding;
    skipTrailingCharsUntilNewline;
    recordEndsOnNewline;
    nullValue;
    skipEmptyLines;
    ignoreTrailingWhitespaces;
    ignoreLeadingWhitespaces;
    headersDisabled;
    headerExtractionEnabled;
    numberOfRecordsToRead;
    emptyValue;
    lineSeparator;
    normalizedLineSeparator;
    comment;
    lazyLoad;
    asMap;
    univocityHeader = [];
    constructor(init) {
        super('UniVocityFixedDataFormat');
        Object.assign(this, init);
    }
}
export class UniVocityHeader extends CamelElement {
    length;
    name;
    constructor(init) {
        super('UniVocityHeader');
        Object.assign(this, init);
    }
}
export class UniVocityTsvDataFormat extends CamelElement {
    dataFormatName = 'univocityTsv';
    id = 'univocityTsv-' + uuidv4().substring(0, 4);
    escapeChar;
    nullValue;
    skipEmptyLines;
    ignoreTrailingWhitespaces;
    ignoreLeadingWhitespaces;
    headersDisabled;
    headerExtractionEnabled;
    numberOfRecordsToRead;
    emptyValue;
    lineSeparator;
    normalizedLineSeparator;
    comment;
    lazyLoad;
    asMap;
    univocityHeader = [];
    constructor(init) {
        super('UniVocityTsvDataFormat');
        Object.assign(this, init);
    }
}
export class XMLSecurityDataFormat extends CamelElement {
    dataFormatName = 'xmlSecurity';
    id = 'xmlSecurity-' + uuidv4().substring(0, 4);
    xmlCipherAlgorithm;
    passPhrase;
    passPhraseByte;
    secureTag;
    secureTagContents;
    keyCipherAlgorithm;
    recipientKeyAlias;
    keyOrTrustStoreParametersRef;
    keyPassword;
    digestAlgorithm;
    mgfAlgorithm;
    addKeyValueForEncryptedKey;
    constructor(init) {
        super('XMLSecurityDataFormat');
        Object.assign(this, init);
    }
}
export class YAMLDataFormat extends CamelElement {
    dataFormatName = 'yaml';
    id = 'yaml-' + uuidv4().substring(0, 4);
    library;
    unmarshalType;
    _constructor;
    representer;
    dumperOptions;
    resolver;
    useApplicationContextClassLoader;
    prettyFlow;
    allowAnyType;
    typeFilter = [];
    maxAliasesForCollections;
    allowRecursiveKeys;
    constructor(init) {
        super('YAMLDataFormat');
        Object.assign(this, init);
    }
}
export class YAMLTypeFilterDefinition extends CamelElement {
    stepName = 'yAMLTypeFilter';
    type;
    value;
    constructor(init) {
        super('YAMLTypeFilterDefinition');
        Object.assign(this, init);
    }
}
export class ZipDeflaterDataFormat extends CamelElement {
    dataFormatName = 'zipDeflater';
    id = 'zipDeflater-' + uuidv4().substring(0, 4);
    compressionLevel;
    constructor(init) {
        super('ZipDeflaterDataFormat');
        Object.assign(this, init);
    }
}
export class ZipFileDataFormat extends CamelElement {
    dataFormatName = 'zipFile';
    id = 'zipFile-' + uuidv4().substring(0, 4);
    usingIterator;
    allowEmptyDirectory;
    preservePathElements;
    maxDecompressedSize;
    constructor(init) {
        super('ZipFileDataFormat');
        Object.assign(this, init);
    }
}
export class DeadLetterChannelDefinition extends CamelElement {
    stepName = 'deadLetterChannel';
    id = 'deadLetterChannel-' + uuidv4().substring(0, 4);
    deadLetterUri = '';
    deadLetterHandleNewException;
    redeliveryPolicy;
    useOriginalMessage;
    useOriginalBody;
    redeliveryPolicyRef;
    loggerRef;
    level;
    logName;
    onRedeliveryRef;
    onExceptionOccurredRef;
    onPrepareFailureRef;
    retryWhileRef;
    executorServiceRef;
    constructor(init) {
        super('DeadLetterChannelDefinition');
        Object.assign(this, init);
    }
}
export class DefaultErrorHandlerDefinition extends CamelElement {
    stepName = 'defaultErrorHandler';
    id = 'defaultErrorHandler-' + uuidv4().substring(0, 4);
    redeliveryPolicy;
    useOriginalMessage;
    useOriginalBody;
    redeliveryPolicyRef;
    loggerRef;
    level;
    logName;
    onRedeliveryRef;
    onExceptionOccurredRef;
    onPrepareFailureRef;
    retryWhileRef;
    executorServiceRef;
    constructor(init) {
        super('DefaultErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class JtaTransactionErrorHandlerDefinition extends CamelElement {
    stepName = 'jtaTransactionErrorHandler';
    id = 'jtaTransactionErrorHandler-' + uuidv4().substring(0, 4);
    transactedPolicyRef;
    rollbackLoggingLevel;
    redeliveryPolicy;
    useOriginalMessage;
    useOriginalBody;
    redeliveryPolicyRef;
    loggerRef;
    level;
    logName;
    onRedeliveryRef;
    onExceptionOccurredRef;
    onPrepareFailureRef;
    retryWhileRef;
    executorServiceRef;
    constructor(init) {
        super('JtaTransactionErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class NoErrorHandlerDefinition extends CamelElement {
    stepName = 'noErrorHandler';
    id = 'noErrorHandler-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('NoErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class RefErrorHandlerDefinition extends CamelElement {
    stepName = 'refErrorHandler';
    id = 'refErrorHandler-' + uuidv4().substring(0, 4);
    ref = '';
    constructor(init) {
        super('RefErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class SpringTransactionErrorHandlerDefinition extends CamelElement {
    stepName = 'springTransactionErrorHandler';
    id = 'springTransactionErrorHandler-' + uuidv4().substring(0, 4);
    transactedPolicyRef;
    rollbackLoggingLevel;
    redeliveryPolicy;
    useOriginalMessage;
    useOriginalBody;
    redeliveryPolicyRef;
    loggerRef;
    level;
    logName;
    onRedeliveryRef;
    onExceptionOccurredRef;
    onPrepareFailureRef;
    retryWhileRef;
    executorServiceRef;
    constructor(init) {
        super('SpringTransactionErrorHandlerDefinition');
        Object.assign(this, init);
    }
}
export class CSimpleExpression extends CamelElement {
    expressionName = 'csimple';
    id = 'csimple-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('CSimpleExpression');
        Object.assign(this, init);
    }
}
export class ConstantExpression extends CamelElement {
    expressionName = 'constant';
    id = 'constant-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('ConstantExpression');
        Object.assign(this, init);
    }
}
export class DatasonnetExpression extends CamelElement {
    expressionName = 'datasonnet';
    id = 'datasonnet-' + uuidv4().substring(0, 4);
    expression = '';
    bodyMediaType;
    outputMediaType;
    source;
    resultType;
    trim;
    constructor(init) {
        super('DatasonnetExpression');
        Object.assign(this, init);
    }
}
export class ExchangePropertyExpression extends CamelElement {
    expressionName = 'exchangeProperty';
    id = 'exchangeProperty-' + uuidv4().substring(0, 4);
    expression = '';
    trim;
    constructor(init) {
        super('ExchangePropertyExpression');
        Object.assign(this, init);
    }
}
export class ExpressionDefinition extends CamelElement {
    stepName = 'expression';
    constant;
    csimple;
    datasonnet;
    exchangeProperty;
    groovy;
    header;
    hl7terser;
    java;
    jq;
    js;
    jsonpath;
    language;
    method;
    mvel;
    ognl;
    python;
    ref;
    simple;
    spel;
    tokenize;
    variable;
    wasm;
    xpath;
    xquery;
    xtokenize;
    constructor(init) {
        super('ExpressionDefinition');
        Object.assign(this, init);
    }
}
export class GroovyExpression extends CamelElement {
    expressionName = 'groovy';
    id = 'groovy-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('GroovyExpression');
        Object.assign(this, init);
    }
}
export class HeaderExpression extends CamelElement {
    expressionName = 'header';
    id = 'header-' + uuidv4().substring(0, 4);
    expression = '';
    trim;
    constructor(init) {
        super('HeaderExpression');
        Object.assign(this, init);
    }
}
export class Hl7TerserExpression extends CamelElement {
    expressionName = 'hl7terser';
    id = 'hl7terser-' + uuidv4().substring(0, 4);
    expression = '';
    source;
    resultType;
    trim;
    constructor(init) {
        super('Hl7TerserExpression');
        Object.assign(this, init);
    }
}
export class JavaExpression extends CamelElement {
    expressionName = 'java';
    id = 'java-' + uuidv4().substring(0, 4);
    expression = '';
    preCompile;
    singleQuotes;
    resultType;
    trim;
    constructor(init) {
        super('JavaExpression');
        Object.assign(this, init);
    }
}
export class JavaScriptExpression extends CamelElement {
    expressionName = 'js';
    id = 'js-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('JavaScriptExpression');
        Object.assign(this, init);
    }
}
export class JqExpression extends CamelElement {
    expressionName = 'jq';
    id = 'jq-' + uuidv4().substring(0, 4);
    expression = '';
    source;
    resultType;
    trim;
    constructor(init) {
        super('JqExpression');
        Object.assign(this, init);
    }
}
export class JsonPathExpression extends CamelElement {
    expressionName = 'jsonpath';
    id = 'jsonpath-' + uuidv4().substring(0, 4);
    expression = '';
    suppressExceptions;
    allowSimple;
    allowEasyPredicate;
    writeAsString;
    unpackArray;
    option;
    source;
    resultType;
    trim;
    constructor(init) {
        super('JsonPathExpression');
        Object.assign(this, init);
    }
}
export class LanguageExpression extends CamelElement {
    expressionName = 'language';
    id = 'language-' + uuidv4().substring(0, 4);
    language = '';
    expression = '';
    trim;
    constructor(init) {
        super('LanguageExpression');
        Object.assign(this, init);
    }
}
export class MethodCallExpression extends CamelElement {
    expressionName = 'method';
    id = 'method-' + uuidv4().substring(0, 4);
    ref;
    method;
    beanType;
    scope;
    validate;
    resultType;
    trim;
    constructor(init) {
        super('MethodCallExpression');
        Object.assign(this, init);
    }
}
export class MvelExpression extends CamelElement {
    expressionName = 'mvel';
    id = 'mvel-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('MvelExpression');
        Object.assign(this, init);
    }
}
export class OgnlExpression extends CamelElement {
    expressionName = 'ognl';
    id = 'ognl-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('OgnlExpression');
        Object.assign(this, init);
    }
}
export class PythonExpression extends CamelElement {
    expressionName = 'python';
    id = 'python-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('PythonExpression');
        Object.assign(this, init);
    }
}
export class RefExpression extends CamelElement {
    expressionName = 'ref';
    id = 'ref-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('RefExpression');
        Object.assign(this, init);
    }
}
export class SimpleExpression extends CamelElement {
    expressionName = 'simple';
    id = 'simple-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('SimpleExpression');
        Object.assign(this, init);
    }
}
export class SpELExpression extends CamelElement {
    expressionName = 'spel';
    id = 'spel-' + uuidv4().substring(0, 4);
    expression = '';
    resultType;
    trim;
    constructor(init) {
        super('SpELExpression');
        Object.assign(this, init);
    }
}
export class TokenizerExpression extends CamelElement {
    expressionName = 'tokenize';
    id = 'tokenize-' + uuidv4().substring(0, 4);
    token = '';
    endToken;
    inheritNamespaceTagName;
    regex;
    xml;
    includeTokens;
    group;
    groupDelimiter;
    skipFirst;
    source;
    resultType;
    trim;
    constructor(init) {
        super('TokenizerExpression');
        Object.assign(this, init);
    }
}
export class VariableExpression extends CamelElement {
    expressionName = 'variable';
    id = 'variable-' + uuidv4().substring(0, 4);
    expression = '';
    trim;
    constructor(init) {
        super('VariableExpression');
        Object.assign(this, init);
    }
}
export class WasmExpression extends CamelElement {
    expressionName = 'wasm';
    id = 'wasm-' + uuidv4().substring(0, 4);
    expression = '';
    module = '';
    resultType;
    trim;
    constructor(init) {
        super('WasmExpression');
        Object.assign(this, init);
    }
}
export class XMLTokenizerExpression extends CamelElement {
    expressionName = 'xtokenize';
    id = 'xtokenize-' + uuidv4().substring(0, 4);
    expression = '';
    mode;
    group;
    namespace = [];
    source;
    resultType;
    trim;
    constructor(init) {
        super('XMLTokenizerExpression');
        Object.assign(this, init);
    }
}
export class XPathExpression extends CamelElement {
    expressionName = 'xpath';
    id = 'xpath-' + uuidv4().substring(0, 4);
    expression = '';
    documentType;
    resultQName;
    saxon;
    factoryRef;
    objectModel;
    logNamespaces;
    threadSafety;
    preCompile;
    namespace = [];
    source;
    resultType;
    trim;
    constructor(init) {
        super('XPathExpression');
        Object.assign(this, init);
    }
}
export class XQueryExpression extends CamelElement {
    expressionName = 'xquery';
    id = 'xquery-' + uuidv4().substring(0, 4);
    expression = '';
    configurationRef;
    namespace = [];
    source;
    resultType;
    trim;
    constructor(init) {
        super('XQueryExpression');
        Object.assign(this, init);
    }
}
export class CustomLoadBalancerDefinition extends CamelElement {
    stepName = 'customLoadBalancer';
    id = 'customLoadBalancer-' + uuidv4().substring(0, 4);
    ref = '';
    constructor(init) {
        super('CustomLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class FailoverLoadBalancerDefinition extends CamelElement {
    stepName = 'failoverLoadBalancer';
    exception = [];
    id = 'failoverLoadBalancer-' + uuidv4().substring(0, 4);
    maximumFailoverAttempts;
    roundRobin;
    sticky;
    constructor(init) {
        super('FailoverLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class RandomLoadBalancerDefinition extends CamelElement {
    stepName = 'randomLoadBalancer';
    id = 'randomLoadBalancer-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('RandomLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class RoundRobinLoadBalancerDefinition extends CamelElement {
    stepName = 'roundRobinLoadBalancer';
    id = 'roundRobinLoadBalancer-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('RoundRobinLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class StickyLoadBalancerDefinition extends CamelElement {
    stepName = 'stickyLoadBalancer';
    correlationExpression;
    id = 'stickyLoadBalancer-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('StickyLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class TopicLoadBalancerDefinition extends CamelElement {
    stepName = 'topicLoadBalancer';
    id = 'topicLoadBalancer-' + uuidv4().substring(0, 4);
    constructor(init) {
        super('TopicLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class WeightedLoadBalancerDefinition extends CamelElement {
    stepName = 'weightedLoadBalancer';
    distributionRatio = '';
    distributionRatioDelimiter;
    id = 'weightedLoadBalancer-' + uuidv4().substring(0, 4);
    roundRobin;
    constructor(init) {
        super('WeightedLoadBalancerDefinition');
        Object.assign(this, init);
    }
}
export class ApiKeyDefinition extends CamelElement {
    stepName = 'apiKey';
    description;
    name = '';
    key = '';
    inHeader;
    inQuery;
    inCookie;
    constructor(init) {
        super('ApiKeyDefinition');
        Object.assign(this, init);
    }
}
export class BasicAuthDefinition extends CamelElement {
    stepName = 'basicAuth';
    description;
    key = '';
    constructor(init) {
        super('BasicAuthDefinition');
        Object.assign(this, init);
    }
}
export class BearerTokenDefinition extends CamelElement {
    stepName = 'bearerToken';
    description;
    key = '';
    format;
    constructor(init) {
        super('BearerTokenDefinition');
        Object.assign(this, init);
    }
}
export class DeleteDefinition extends CamelElement {
    stepName = 'delete';
    id = 'delete-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('DeleteDefinition');
        Object.assign(this, init);
    }
}
export class GetDefinition extends CamelElement {
    stepName = 'get';
    id = 'get-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('GetDefinition');
        Object.assign(this, init);
    }
}
export class HeadDefinition extends CamelElement {
    stepName = 'head';
    id = 'head-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('HeadDefinition');
        Object.assign(this, init);
    }
}
export class MutualTLSDefinition extends CamelElement {
    stepName = 'mutualTLS';
    description;
    key = '';
    constructor(init) {
        super('MutualTLSDefinition');
        Object.assign(this, init);
    }
}
export class OAuth2Definition extends CamelElement {
    stepName = 'oAuth2';
    authorizationUrl;
    description;
    flow;
    key = '';
    refreshUrl;
    scopes = [];
    tokenUrl;
    constructor(init) {
        super('OAuth2Definition');
        Object.assign(this, init);
    }
}
export class OpenIdConnectDefinition extends CamelElement {
    stepName = 'openIdConnect';
    description;
    key = '';
    url = '';
    constructor(init) {
        super('OpenIdConnectDefinition');
        Object.assign(this, init);
    }
}
export class ParamDefinition extends CamelElement {
    stepName = 'param';
    description;
    name = '';
    type;
    defaultValue;
    required;
    collectionFormat;
    arrayType;
    dataType;
    dataFormat;
    allowableValues = [];
    examples = [];
    constructor(init) {
        super('ParamDefinition');
        Object.assign(this, init);
    }
}
export class PatchDefinition extends CamelElement {
    stepName = 'patch';
    id = 'patch-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('PatchDefinition');
        Object.assign(this, init);
    }
}
export class PostDefinition extends CamelElement {
    stepName = 'post';
    id = 'post-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('PostDefinition');
        Object.assign(this, init);
    }
}
export class PutDefinition extends CamelElement {
    stepName = 'put';
    id = 'put-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    to;
    consumes;
    produces;
    type;
    outType;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    deprecated;
    routeId;
    param = [];
    responseMessage = [];
    security = [];
    constructor(init) {
        super('PutDefinition');
        Object.assign(this, init);
    }
}
export class ResponseHeaderDefinition extends CamelElement {
    stepName = 'responseHeader';
    description;
    name = '';
    collectionFormat;
    arrayType;
    dataType;
    dataFormat;
    allowableValues = [];
    example;
    constructor(init) {
        super('ResponseHeaderDefinition');
        Object.assign(this, init);
    }
}
export class ResponseMessageDefinition extends CamelElement {
    stepName = 'responseMessage';
    code;
    message = '';
    responseModel;
    header = [];
    examples = [];
    constructor(init) {
        super('ResponseMessageDefinition');
        Object.assign(this, init);
    }
}
export class RestBindingDefinition extends CamelElement {
    stepName = 'restBinding';
    id = 'restBinding-' + uuidv4().substring(0, 4);
    description;
    consumes;
    produces;
    bindingMode;
    type;
    outType;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    component;
    constructor(init) {
        super('RestBindingDefinition');
        Object.assign(this, init);
    }
}
export class RestConfigurationDefinition extends CamelElement {
    stepName = 'restConfiguration';
    component;
    apiComponent;
    producerComponent;
    scheme;
    host;
    port;
    apiHost;
    useXForwardHeaders;
    producerApiDoc;
    contextPath;
    apiContextPath;
    apiContextRouteId;
    apiVendorExtension;
    hostNameResolver;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    inlineRoutes;
    jsonDataFormat;
    xmlDataFormat;
    componentProperty = [];
    endpointProperty = [];
    consumerProperty = [];
    dataFormatProperty = [];
    apiProperty = [];
    corsHeaders = [];
    constructor(init) {
        super('RestConfigurationDefinition');
        Object.assign(this, init);
    }
}
export class RestDefinition extends CamelElement {
    stepName = 'rest';
    id = 'rest-' + uuidv4().substring(0, 4);
    description;
    disabled;
    path;
    consumes;
    produces;
    bindingMode;
    skipBindingOnErrorCode;
    clientRequestValidation;
    enableCORS;
    enableNoContentResponse;
    apiDocs;
    tag;
    securityDefinitions;
    securityRequirements = [];
    delete = [];
    get = [];
    head = [];
    patch = [];
    post = [];
    put = [];
    constructor(init) {
        super('RestDefinition');
        Object.assign(this, init);
    }
}
export class RestPropertyDefinition extends CamelElement {
    stepName = 'restProperty';
    key = '';
    value = '';
    constructor(init) {
        super('RestPropertyDefinition');
        Object.assign(this, init);
    }
}
export class RestSecuritiesDefinition extends CamelElement {
    stepName = 'securityDefinitions';
    apiKey;
    basicAuth;
    bearer;
    mutualTLS;
    oauth2;
    openIdConnect;
    constructor(init) {
        super('RestSecuritiesDefinition');
        Object.assign(this, init);
    }
}
export class RestsDefinition extends CamelElement {
    stepName = 'rests';
    id = 'rests-' + uuidv4().substring(0, 4);
    description;
    rest = [];
    constructor(init) {
        super('RestsDefinition');
        Object.assign(this, init);
    }
}
export class SecurityDefinition extends CamelElement {
    stepName = 'security';
    key = '';
    scopes;
    constructor(init) {
        super('SecurityDefinition');
        Object.assign(this, init);
    }
}
export class CustomTransformerDefinition extends CamelElement {
    stepName = 'customTransformer';
    className;
    fromType;
    name;
    ref;
    scheme;
    toType;
    constructor(init) {
        super('CustomTransformerDefinition');
        Object.assign(this, init);
    }
}
export class DataFormatTransformerDefinition extends CamelElement {
    stepName = 'dataFormatTransformer';
    asn1;
    avro;
    barcode;
    base64;
    beanio;
    bindy;
    cbor;
    crypto;
    csv;
    custom;
    fhirJson;
    fhirXml;
    flatpack;
    grok;
    gzipDeflater;
    hl7;
    ical;
    jacksonXml;
    jaxb;
    json;
    jsonApi;
    lzf;
    mimeMultipart;
    parquetAvro;
    pgp;
    protobuf;
    rss;
    soap;
    swiftMt;
    swiftMx;
    syslog;
    tarFile;
    thrift;
    tidyMarkup;
    univocityCsv;
    univocityFixed;
    univocityTsv;
    xmlSecurity;
    yaml;
    zipDeflater;
    zipFile;
    fromType;
    name;
    scheme;
    toType;
    constructor(init) {
        super('DataFormatTransformerDefinition');
        Object.assign(this, init);
    }
}
export class EndpointTransformerDefinition extends CamelElement {
    stepName = 'endpointTransformer';
    fromType;
    name;
    ref;
    scheme;
    toType;
    uri;
    constructor(init) {
        super('EndpointTransformerDefinition');
        Object.assign(this, init);
    }
}
export class LoadTransformerDefinition extends CamelElement {
    stepName = 'loadTransformer';
    defaults;
    fromType;
    name;
    packageScan;
    scheme;
    toType;
    constructor(init) {
        super('LoadTransformerDefinition');
        Object.assign(this, init);
    }
}
export class TransformersDefinition extends CamelElement {
    stepName = 'transformers';
    customTransformer;
    dataFormatTransformer;
    endpointTransformer;
    loadTransformer;
    constructor(init) {
        super('TransformersDefinition');
        Object.assign(this, init);
    }
}
export class CustomValidatorDefinition extends CamelElement {
    stepName = 'customValidator';
    className;
    ref;
    type;
    constructor(init) {
        super('CustomValidatorDefinition');
        Object.assign(this, init);
    }
}
export class EndpointValidatorDefinition extends CamelElement {
    stepName = 'endpointValidator';
    ref;
    type;
    uri;
    constructor(init) {
        super('EndpointValidatorDefinition');
        Object.assign(this, init);
    }
}
export class PredicateValidatorDefinition extends CamelElement {
    stepName = 'predicateValidator';
    expression;
    type;
    constructor(init) {
        super('PredicateValidatorDefinition');
        Object.assign(this, init);
    }
}
export class ValidatorsDefinition extends CamelElement {
    stepName = 'validators';
    customValidator;
    endpointValidator;
    predicateValidator;
    constructor(init) {
        super('ValidatorsDefinition');
        Object.assign(this, init);
    }
}
