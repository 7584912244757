import React from 'react';
import {
    Bullseye,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant,
    PageSection,
    Panel, PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Title,
    Pagination,
    PaginationVariant,
    SplitItem,
    Split
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {useProjectDeadletterStore, useProjectStore} from "../../api/ProjectStore";
import {ProjectDeadletterToolbar} from "./ProjectDeadletterToolbar";
import {shallow} from "zustand/shallow";
import {PaginationRequest, ProjectDeadletterMessageFilter} from "../../api/ProjectModels";
import {ProjectService} from "../../api/ProjectService";
import {DeadletterMessageInspectionButton} from "./DeadletterMessageInspectionButton";
import {DeadletterMessageRedeliveryButton} from "./DeadletterMessageRedeliveryButton";
import {DeadletterMessageStackTraceLink} from "./DeadletterMessageStackTraceLink";

export const ProjectDeadletterTab = () => {
    const { messages, paginationRequest, setPaginationRequest, paginationResult, activeFilter } = useProjectDeadletterStore();
    const [project] = useProjectStore((state) => [state.project], shallow )

    let lineNumber = 1;

    const getDeadletterMessages = (pagination: PaginationRequest, filter: ProjectDeadletterMessageFilter) => {
        return ProjectService.getProjectDeadletterMessages(project.projectId, pagination, filter);
    }

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        const newPaginationRequest = new PaginationRequest(newPage, paginationRequest.size);

        setPaginationRequest(newPaginationRequest);
        return getDeadletterMessages(newPaginationRequest, activeFilter);
    };

    const onPerPageSelect = (
        _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
        newPerPage: number,
        newPage: number
    ) => {
        const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

        setPaginationRequest(newPaginationRequest);
        return getDeadletterMessages(newPaginationRequest, activeFilter);
    };

    const transformStatus = (status: string) => {
        switch (status) {
            case 'NEW':
                return 'New'
            case 'REDELIVERY_REQUESTED':
                return 'Redelivery Requested';
            case 'REDELIVERED':
                return 'Redelivered';
            case 'REDELIVERY_FAILED':
                return 'Redelivery Failed';
            default:
                return 'Unknown';
        }
    }

    return (
        <PageSection className="project-tab-panel" padding={{default: "padding"}}>
            <Panel isScrollable>
                <PanelHeader style={{paddingBottom:'10px'}}>
                    <ProjectDeadletterToolbar/>
                </PanelHeader>
                <PanelMain maxHeight={'calc(100vh - 300px)'}>
                    <PanelMainBody>
                        <Table aria-label="Deadletter Messages" variant={"compact"} className={"table"}>
                            <Thead>
                                <Tr>
                                    <Th key='exchangeId'>Exchange Id</Th>
                                    <Th key='messageId'>Message Id</Th>
                                    <Th key='routeId'>Route Id</Th>
                                    <Th key='redeliveryEndpoint'>Redelivery Endpoint</Th>
                                    <Th key='exceptionMessage'>Error Message</Th>
                                    <Th key='createdAt'>Failed On</Th>
                                    <Th key='status'>Status</Th>
                                    <Th key='actions'>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {messages.map(line => {
                                    lineNumber = lineNumber + 1;
                                    let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                    return <Tr key={line.id} style={{backgroundColor: backgroundColor}}>
                                        <Td><small>{line.exchangeId}</small></Td>
                                        <Td><small>{line.messageId}</small></Td>
                                        <Td>{line.routeId}</Td>
                                        <Td>{line.redeliveryEndpoint}</Td>
                                        <Td>
                                            <DeadletterMessageStackTraceLink errorMessage={line.exceptionMessage} stackTrace={line.exceptionStackTrace} />
                                        </Td>
                                        <Td>{line.createdAt.toString()}</Td>
                                        <Td>{transformStatus(line.status)}</Td>
                                        <Td>
                                            <Split hasGutter>
                                                <SplitItem><DeadletterMessageInspectionButton headers={line.headers} bodyValue={line.bodyValue} bodyType={line.bodyType} /></SplitItem>
                                                <SplitItem><DeadletterMessageRedeliveryButton id={line.id} messageId={line.messageId} /></SplitItem>
                                            </Split>
                                        </Td>
                                    </Tr>
                                })}
                                {messages.length === 0 &&
                                    <Tr>
                                        <Td colSpan={8}>
                                            <Bullseye>
                                                <EmptyState variant={EmptyStateVariant.sm}>
                                                    <EmptyStateIcon icon={SearchIcon}/>
                                                    <Title headingLevel="h2" size="lg">
                                                        No results found
                                                    </Title>
                                                </EmptyState>
                                            </Bullseye>
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </PanelMainBody>
                </PanelMain>
                <PanelFooter>
                    <Pagination
                        itemCount={paginationResult.totalItems}
                        widgetId="bottom-deadletter"
                        perPage={paginationRequest.size}
                        page={paginationRequest.page}
                        variant={PaginationVariant.bottom}
                        onSetPage={onSetPage}
                        onPerPageSelect={onPerPageSelect}
                    />
                </PanelFooter>
            </Panel>
        </PageSection>
    )
}
