import React from 'react';
import {Button, Modal, ModalVariant} from '@patternfly/react-core';
import {ProjectService} from "../../api/ProjectService";
import {useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";

export interface DeadletterMessageRedeliveryButtonProps extends React.PropsWithoutRef<any> {
    id: number;
    messageId: string;
}

export const DeadletterMessageRedeliveryButton: React.FunctionComponent<DeadletterMessageRedeliveryButtonProps> = ({
    id = 0,
    messageId = ''
}: DeadletterMessageRedeliveryButtonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const handleMessageRedeliveryConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        redeliverDeadletterMessage(id);
        setIsModalOpen(!isModalOpen);
    };

    const redeliverDeadletterMessage = (id: number) => {
        return ProjectService.redeliverDeadletterMessage(project.projectId, id);
    }

    return (
        <React.Fragment>
            <Button variant="danger" onClick={handleModalToggle} >
                Redeliver
            </Button>
            <Modal
                variant={ModalVariant.medium}
                title="Warning"
                titleIconVariant="warning"
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleMessageRedeliveryConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                <span id="modal-title-icon-description">
                  Are you sure you want to redeliver message {messageId}?
                </span>
            </Modal>
        </React.Fragment>
    );
};
