/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, {FormEvent} from 'react';
import {
    Button,
    DatePicker,
    Flex,
    FlexItem,
    InputGroup,
    MenuToggle,
    MenuToggleElement,
    Select,
    SelectList,
    SelectOption,
    TextInput,
    TimePicker
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import SyncAltIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {ProjectService} from "../../api/ProjectService";
import {PaginationRequest, ProjectDeadletterMessageFilter} from "../../api/ProjectModels";
import {useProjectDeadletterStore, useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import dayjs from "dayjs";

export class Time {
    time: string
    hour: number | undefined
    minute: number | undefined
    second: number | undefined
    isValid: boolean | undefined


    constructor(time: string, hour: number | undefined, minute: number | undefined, second: number | undefined, isValid: boolean | undefined) {
        this.time = time;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.isValid = isValid;
    }
}

export const ProjectDeadletterToolbar = () => {
    const [project] = useProjectStore((state) => [state.project], shallow )
    const [exchangeId, setExchangeId] = React.useState('');
    const [messageId, setMessageId] = React.useState('');
    const [routeId, setRouteId] = React.useState('');
    const [status, setStatus] = React.useState('Select status');
    const { paginationRequest, setPaginationRequest} = useProjectDeadletterStore();
    const [isStatusSelectOpen, setIsStatusSelectOpen] = React.useState(false);

    const [fromDate, setFromDate] = React.useState<Date>();
    const [fromTime, setFromTime] = React.useState<Time>();

    const [toDate, setToDate] = React.useState<Date>();
    const [toTime, setToTime] = React.useState<Time>();

    const toValidator = (date: Date) => {
        if (!fromDate) {
            return '';
        }

        let from = dayjs(fromDate).utc(true);
        let to = dayjs(date).utc(true);

        return (from.isAfter(to) ? 'To date must be after from date' : '');
    };

    const onFromDateChange = (event: FormEvent<HTMLInputElement>, inputDate: string, newFromDate: Date | undefined) => {
        if (newFromDate) {
            setFromDate(newFromDate);
        }
        else {
            setFromDate(undefined);
            setFromTime(undefined);
        }
    };

    const onFromTimeChange = (
        event: FormEvent<HTMLInputElement>,
        time: string,
        hour: number | undefined,
        minute: number | undefined,
        seconds: number | undefined,
        isValid: boolean | undefined
    )=> {
        let fromTime = new Time(time, hour, minute, seconds, isValid);
        setFromTime(fromTime);
    };

    const onToDateChange = (event: FormEvent<HTMLInputElement>, inputDate: string, newToDate: Date | undefined) => {
        if (newToDate) {
            setToDate(newToDate);
        }
        else {
            setToDate(undefined);
            setToTime(undefined);
        }
    };

    const onToTimeChange = (
        event: FormEvent<HTMLInputElement>,
        time: string,
        hour: number | undefined,
        minute: number | undefined,
        seconds: number | undefined,
        isValid: boolean | undefined
    )=> {
        let toTime = new Time(time, hour, minute, seconds, isValid);
        setToTime(toTime);
    };

    const constructFilterDateTime = (date: Date | undefined, time: Time | undefined) => {
        // Date is provided
        if (date) {
            // Time is provided
            if (time && time.isValid) {
                return dayjs(date).utc(true)
                    .hour(time.hour || 0)
                    .minute(time.minute || 0)
                    .second(time.second || 0)
            }

            return dayjs(date).utc(true);
        }

        // Nothing was provided
        return undefined;
    }

    const transformStatus = (status: string) => {
        switch (status) {
            case 'New':
                return 'NEW'
            case 'Redelivery Requested':
                return 'REDELIVERY_REQUESTED';
            case 'Redelivered':
                return 'REDELIVERED';
            case 'Redelivery Failed':
                return 'REDELIVERY_FAILED';
            default:
                return undefined;
        }
    }

    const searchDeadletterMessages = () => {
        let from = constructFilterDateTime(fromDate, fromTime);
        let to = constructFilterDateTime(toDate, toTime);

        const newPaginationRequest = new PaginationRequest(1, paginationRequest.size);
        setPaginationRequest(newPaginationRequest);

        const filter = new ProjectDeadletterMessageFilter();
        filter.from = from?.toDate();
        filter.to = to?.toDate();
        filter.exchangeId = exchangeId;
        filter.messageId = messageId;
        filter.routeId = routeId;
        filter.status = transformStatus(status);

        ProjectService.getProjectDeadletterMessages(project.projectId, newPaginationRequest, filter);

        useProjectDeadletterStore.getState().setActiveFilter(filter);
    }

    const onToggleClick = () => {
        setIsStatusSelectOpen(!isStatusSelectOpen);
    };

    const onStatusSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, value: string | number | undefined) => {
        setStatus(value as string);
        setIsStatusSelectOpen(false);
    };

    const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
        <MenuToggle
            ref={toggleRef}
            onClick={onToggleClick}
            isExpanded={isStatusSelectOpen}
            style={{width: '200px'} as React.CSSProperties}
        >
            {status}
        </MenuToggle>
    );

    return (
        <Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <DatePicker
                            value={fromDate ? dayjs(fromDate).utc(true).format("YYYY-MM-DD") : undefined}
                            onChange={onFromDateChange}
                            aria-label="Start date"
                            placeholder="YYYY-MM-DD"
                        />
                        <TimePicker
                            aria-label="Start time"
                            style={{width: '150px'}}
                            time={fromTime?.time || '12:00 AM'}
                            onChange={onFromTimeChange}
                            isDisabled={!fromDate}
                            stepMinutes={15}
                        />
                    </InputGroup>
                </FlexItem>
                <FlexItem>
                    to
                </FlexItem>
                <FlexItem>
                    <InputGroup>
                        <DatePicker
                            onChange={onToDateChange}
                            rangeStart={fromDate ? dayjs(fromDate).utc(true).toDate() : undefined}
                            validators={[toValidator]}
                            aria-label="End date"
                            placeholder="YYYY-MM-DD"
                        />
                        <TimePicker
                            style={{width: '150px'}}
                            time={toTime?.time || '12:00 AM'}
                            onChange={onToTimeChange}
                            isDisabled={!toDate}
                            stepMinutes={15}
                        />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <TextInput style={{width: '300px'}}  value={exchangeId} type="text" onChange={event => setExchangeId(event.currentTarget.value)} aria-label="Exchange Id" placeholder="Exchange Id" />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <TextInput style={{width: '300px'}}  value={messageId} type="text" onChange={event => setMessageId(event.currentTarget.value)} aria-label="Message Id" placeholder="Message Id" />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <TextInput style={{width: '200px'}}  value={routeId} type="text" onChange={event => setRouteId(event.currentTarget.value)} aria-label="Route Id" placeholder="Route Id" />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <Select
                        id="status-select"
                        isOpen={isStatusSelectOpen}
                        onSelect={onStatusSelect}
                        onOpenChange={(isOpen) => setIsStatusSelectOpen(isOpen)}
                        toggle={toggle}
                        shouldFocusToggleOnSelect
                    >
                        <SelectList>
                            <SelectOption value="Select status">Select status</SelectOption>
                            <SelectOption value="New">New</SelectOption>
                            <SelectOption value="Redelivery Requested">Redelivery Requested</SelectOption>
                            <SelectOption value="Redelivered">Redelivered</SelectOption>
                            <SelectOption value="Redelivery Failed">Redelivery Failed</SelectOption>
                        </SelectList>
                    </Select>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <Button size={"sm"} variant={"secondary"} icon={<SyncAltIcon/>}
                            onClick={e =>
                                searchDeadletterMessages()}>Search</Button>
                </FlexItem>
            </Flex>
        </Flex>

    );
}
