import React, {useEffect, useRef, useState} from 'react';
import {
    Bullseye,
    Button, Checkbox,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant, Label,
    PageSection, Pagination, PaginationVariant,
    Panel, PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Text,
    TextContent, Title,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
} from '@patternfly/react-core';
import '../../designer/karavan.css';

import {PaginationRequest} from "../../api/ProjectModels";
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";

import {shallow} from "zustand/shallow";

import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {MainToolbar} from "../../designer/MainToolbar";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {useMilestoneProductsStore} from "../../api/MilestoneProductStore";
import {
    MilestoneProductFilter,
    MilestoneProductStep,
    MilestoneProductStepFilter
} from "../../api/MilestoneProductModels";
import {useMilestoneProductStepsStore} from "../../api/MilestoneProductStepsStore";
import {MilestoneProductStepsService} from "../../api/MilestoneProductStepsService";
import {useParams} from "react-router-dom";
import {SmhApi} from "../../api/SmhApi";
import {MilestoneProductDetailsToolbar} from "./MilestoneProductDetailsToolbar";
import {AssignMilestonesModal} from "./AssignMilestonesModal";
import FlagIcon from "@patternfly/react-icons/dist/esm/icons/flag-icon";



export const MilestoneProductDetailsPage = () => {

    const {
        milestoneProductSteps,
        paginationRequest,
        setPaginationRequest,
        paginationResult,
        activeFilter,
        setActiveFilter
    } = useMilestoneProductStepsStore();

    const defaultFilter =  MilestoneProductFilter.default();
    const defaultPaginationRequest =  PaginationRequest.default();

    const [milestoneProducts, setProduct] = useMilestoneProductsStore((state) => [state.milestoneProducts,  state.setMilestoneProduct], shallow)
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [selectedMilestoneSteps, setSelectedMilestoneSteps] = useState<MilestoneProductStep[]>([]);

    let {productId} = useParams();

    let lineNumber = 1;

    useEffect(() => {
        const p = milestoneProducts.filter(product => product.id === productId).at(0);
        if (p) {
            setProduct("select", p);
        } else if (productId) {
            SmhApi.getMilestoneProduct(productId, product => setProduct("select", product));
        }

        return () => {}
    }, []);

    useEffect(() => {
        setPaginationRequest(defaultPaginationRequest)
        setActiveFilter(defaultFilter)
    }, []);


    useEffect(() => {
        if (productId) {
            MilestoneProductStepsService.getMilestoneProductSteps(productId, defaultPaginationRequest, defaultFilter);
        }
    }, []);

    const getProductSteps = (pagination: PaginationRequest, filter: MilestoneProductStepFilter) => {
        if (productId) {
            return MilestoneProductStepsService.getMilestoneProductSteps(productId, pagination, defaultFilter);
        }
    }

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        const newPaginationRequest = new PaginationRequest(newPage, paginationRequest.size);

        setPaginationRequest(newPaginationRequest);
        return getProductSteps(newPaginationRequest, activeFilter);
    };

    const onPerPageSelect = (
        _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
        newPerPage: number,
        newPage: number
    ) => {
        const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

        setPaginationRequest(newPaginationRequest);
        return getProductSteps(newPaginationRequest, activeFilter);
    };


    const handleMilestoneSelect = (milestoneStep :MilestoneProductStep) => {
        const milestones = [...selectedMilestoneSteps];
        const index = milestones.indexOf(milestoneStep);
        if (index === -1) {
            milestones.push(milestoneStep);
        } else {
            milestones.splice(index, 1);
        }
        setSelectedMilestoneSteps(milestones);

        milestones.length > 0 ? disableAssignButton(false) : disableAssignButton(true)
    }

    const isChecked = (productStepId :Number) => {
        return selectedMilestoneSteps.some(el =>  el.id === productStepId)
    }

    const disableAssignButton = (status :boolean) => {
        setButtonDisabled(status);
    }

    const onSaveMilestone = () => {
        if (productId) {
            MilestoneProductStepsService.getMilestoneProductSteps(productId, paginationRequest, activeFilter);
        }
        setSelectedMilestoneSteps([]);
        disableAssignButton(true)

    }

    function getTitle() {
        return <TextContent>
            <Text component="h2">Product details</Text>
        </TextContent>
    }

    function getTools() {
        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button variant="link" icon={<RefreshIcon/>} onClick={e => getProductSteps(paginationRequest, activeFilter)}/>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    function getIcon() {
        return ( <FlagIcon/> )
    }

    function getFirstMilestoneStep(milestoneStepId :number) {
        const minOrderStep = Math.min(...milestoneProductSteps.filter(step => step.milestoneStep?.id === milestoneStepId).map(o => o.sortOrder))
        return milestoneProductSteps
            .filter(obj => (obj.sortOrder === minOrderStep))[0];
    }

    function isFirstStepInMilestone(productStep :MilestoneProductStep) {
        if(productStep.milestoneStep){
            const firstStep = getFirstMilestoneStep(productStep.milestoneStep?.id);
            return firstStep ? firstStep.id === productStep.id : false;
        } else {
            return false;
        }
    }

    function getMilestoneLabel(productStep :MilestoneProductStep) {
        if(isFirstStepInMilestone(productStep)){
           return (
               <Label icon={getIcon()} color={"blue"}>
                   {productStep?.milestoneStep?.name}
               </Label>
           )
        } else {
            return (
                <Label color={"grey"}>
                    {productStep?.milestoneStep?.name}
                </Label>
            )
        }
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={getTitle()} tools={getTools()}/>
            </PageSection>
            <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                <Panel isScrollable>
                    <PanelHeader style={{paddingBottom: '10px'}}>
                        <MilestoneProductDetailsToolbar canAddMilestones={isButtonDisabled}/>
                    </PanelHeader>
                    <PanelMain maxHeight={'calc(100vh - 300px)'}>
                        <PanelMainBody>
                            <Table aria-label="Products" variant={"compact"} className={"table"}>
                                <Thead>
                                    <Tr>
                                        <Th key='milestone-id'> </Th>
                                        <Th key='name'>Name</Th>
                                        <Th key='order'>Order</Th>
                                        <Th key='process-name'>Process Name</Th>
                                        <Th key='description'>Description</Th>
                                        <Th key='milestone'>Milestone</Th>
                                        <Th key='createdAt'>Created At</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {milestoneProductSteps.map((productStep) => {
                                        lineNumber = lineNumber + 1;
                                        let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                        return <Tr key={lineNumber} style={{backgroundColor: backgroundColor}}>
                                            <Td modifier="fitContent" textCenter={true}>
                                                <Checkbox
                                                          id={"product-step-" +productStep.id.toString()}
                                                          isChecked = {isChecked(productStep.id)}
                                                          value={productStep.id}
                                                          isDisabled={!!productStep?.milestoneStep}
                                                          onChange={() => handleMilestoneSelect(productStep)}/>
                                            </Td>
                                            <Td>{productStep.name}</Td>
                                            <Td>{productStep.sortOrder}</Td>
                                            <Td>{productStep.processName}</Td>
                                            <Td>{productStep.description}</Td>
                                            <Td>{productStep?.milestoneStep && getMilestoneLabel(productStep)}</Td>
                                            <Td>{productStep.createdAt.toString()}</Td>
                                        </Tr>
                                    })}
                                    {milestoneProductSteps.length === 0 &&
                                        <Tr>
                                            <Td colSpan={6}>
                                                <Bullseye>
                                                    <EmptyState variant={EmptyStateVariant.sm}>
                                                        <EmptyStateIcon icon={SearchIcon}/>
                                                        <Title headingLevel="h2" size="lg">
                                                            No results found
                                                        </Title>
                                                    </EmptyState>
                                                </Bullseye>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </PanelMainBody>
                    </PanelMain>
                    <PanelFooter>
                        <Pagination
                            itemCount={paginationResult.totalItems}
                            widgetId="bottom-mileston-products"
                            perPage={paginationRequest.size}
                            page={paginationRequest.page}
                            variant={PaginationVariant.bottom}
                            onSetPage={onSetPage}
                            onPerPageSelect={onPerPageSelect}
                        />
                    </PanelFooter>
                </Panel>
                <AssignMilestonesModal productId={productId} selectedMilestoneSteps = {selectedMilestoneSteps} onSaveMilestone={onSaveMilestone} />
            </PageSection>
        </PageSection>
    )
}